import React, { useEffect, useState, useRef } from "react"
import Layout from "../layout"
import SEO from "../seo"
import Paginator from "../paginator"
import zoomButton from "../../assets/images/icons/zoom.svg"
import { SharePanel } from "../share-panel"
import fetch from "node-fetch"
import moment from "moment"
import fileDownload from "js-file-download"
import { backendHost } from "../../env.json"
import MarkdownViewer from "../MarkdownViewer"

const KeyValue = props => (
  <div className="pa2 flex flex-row-ns flex-column">
    <div className="w-30-ns w-100">
      <h3 style={{ fontSize: "16px", margin: "0", padding: "0" }}>
        {props.keyName}
      </h3>
    </div>
    <div className={`w-70-ns w-100 ${props.markdown ? "b p-f-16" : ""}`}>
      {props.markdown ? (
        <MarkdownViewer>{props.value}</MarkdownViewer>
      ) : (
        <span className="uk-text-justify uk-inline">{props.value}</span>
      )}
    </div>
  </div>
)

export default function ImageGalleryPage({ pageContext }) {
  const data = pageContext
  const [imageSizes, setImageSizes] = useState([])
  const mediaDownloadRef = useRef(null)

  const downloadFile = (url, title) => {
    fetch(url)
      .then(response => response.blob())
      .then(response => {
        const ext = url.split(".")
        fileDownload(
          response,
          title.split(" ").join("-") + "." + ext[ext.length - 1]
        )
      })
  }

  return (
    <Layout>
      <SEO title="What Hope Probe is seeing" />
      <main>
        <div className="standard-container">
          <h2 className="relative uk-text-center mt6 mb5">
            Images and scientific results
            <br /> from hope probe
            <div
              className="text-underline-heavy"
              style={{ marginLeft: "auto", marginRight: "auto" }}
            ></div>
          </h2>
          <div id="gallery-lightbox">
            {data.content.map((image, index) => {
              const singleMedia = image.media.length <= 1
              return (
                <div className="flex image-card flex-column flex-row-ns items-center items-start-ns justify-center mb5">
                  <div
                    className="mr3-ns mb3 mb0-ns"
                    style={{
                      display: "inline-block",
                      position: "relative",
                      maxWidth: "300px",
                    }}
                  >
                    <div
                      {...(!singleMedia && {
                        "data-uk-slider": "center: true",
                      })}
                    >
                      <div className="uk-position-relative uk-dark uk-slider-container">
                        <ul
                          className="uk-slider-items uk-child-width-1-1"
                          data-uk-lightbox="animation: slide"
                        >
                          {image.media.map((_image, imageIndex) => (
                            <a
                              href={_image.formats.medium.publicURL}
                              {...(!singleMedia && {
                                "data-uk-slider-item": true,
                              })}
                              className={imageIndex === 0 ? "uk-active" : ""}
                            >
                              <img src={_image.formats.medium.publicURL} />
                            </a>
                          ))}
                        </ul>
                        <a
                          className="uk-position-center-left uk-position-small uk-hidden-hover"
                          uk-slidenav-previous
                          uk-slider-item="previous"
                        >
                          <span
                            className="icon-white"
                            uk-icon="icon: chevron-left; ratio: 2"
                          />
                        </a>
                        <a
                          className="uk-position-center-right uk-position-small uk-hidden-hover"
                          uk-slidenav-next
                          uk-slider-item="next"
                        >
                          <span
                            className="icon-white"
                            uk-icon="icon: chevron-right; ratio: 2"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="w-100 items-center f-mbt">
                    <KeyValue keyName="Title" value={image.title}></KeyValue>
                    <KeyValue
                      keyName="Date"
                      value={
                        moment(image.date)
                          .format("MMMM Do YYYY, h:mm:ss a")
                          .toString()
                          .split(",")[0]
                      }
                    />
                    <KeyValue
                      keyName="Instruments"
                      value={image.instruments}
                    ></KeyValue>
                    <KeyValue
                      keyName="Description"
                      value={image.description}
                      markdown={true}
                    ></KeyValue>
                    <KeyValue
                      keyName="Download image"
                      value={
                        <>
                          <span
                            className="span-link"
                            onClick={e =>
                              downloadFile(
                                `${backendHost}/whpis/whpis-${
                                  image.strapiId || image.id
                                }.zip`,
                                image.title
                              )
                            }
                            style={{
                              cursor: "pointer",
                              color: "#fff",
                              fontFamily: "martianbthai",
                              textDecoration: "underline",
                            }}
                          >
                            Download (High Resolution)
                          </span>
                          &nbsp;
                          {Math.round(
                            image.media.reduce(
                              (total, c) => total + c.size,
                              0
                            ) / 1024
                          )}{" "}
                          MB
                          {/* <DownloadLink className="uk-link" label="Download (High Resolution)" filename={image.media.publicURL} exportFile={() => "My cached data"} /> */}
                          {/* <a href={image.media.publicURL} filename="bingo.jpeg" target="_blank" data-download>Download (High Resolution)</a> */}
                        </>
                      }
                    ></KeyValue>
                  </div>
                </div>
              )
            })}
          </div>
          <div
            style={{
              marginTop: "60px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Paginator
              totalPages={data.totalPages}
              pageNumber={data.pageNumber}
              prefix={"/gallery/images-of-hope-probe/"}
            />
          </div>
        </div>
        <div>
          <a ref={mediaDownloadRef} download="download.jpg" />
        </div>
      </main>
    </Layout>
  )
}
